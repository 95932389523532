import React from 'react'
import PropTypes from 'prop-types'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery()

  React.useEffect(() => {
    document.documentElement.style.setProperty(`--mainPaddingMobile`, pageContext?.header === 'transparent' ? '0' : '80px');
    document.documentElement.style.setProperty(`--mainPaddingDesktop`, pageContext?.header === 'transparent' ? '0' : '82px');
    document.documentElement.style.setProperty(`--headerBackground`, pageContext?.header === 'transparent' ? 'transparent' : '#E53B16');
    document.documentElement.style.setProperty(`--contactBackground`, pageContext?.header === 'transparent' ? '#E53B16' : '#3E3D40');
    document.documentElement.style.setProperty(`--contactBackgroundHover`, pageContext?.header === 'transparent' ? '#3E3D40' : '#E53B16');
  }, [pageContext])

  return (
    <>
      <Header location={location} data={data} pageContext={pageContext} />
      <main>{ children }</main>
      <Footer data={data} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
