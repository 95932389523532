import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo, LinkedIn, Email } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    subActive: false,
    scrolled: false,
    initial: true,
    menu: [],
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    let { scrolled } = this.state
    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 1
    requestAnimationFrame(() => {
      this.setState({ scrolled })
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ 
      offCanvas: false,
      subActive: false,
    })
  }

  _toggleOffCanvas = () => {
    this.setState({ 
      offCanvas: !this.state.offCanvas,
      subActive: false,
    })
  }

  menuRollover = (key) => {
    let { subActive, menu, initial } = this.state
    subActive = false
    initial = false
    if ( menu[key] ) {
      menu[key] = true
    } else {
      for ( let i = 0; i < menu.length; i++ ) {
        menu[i] = false
      }
      menu[key] = true
    }
    if (key < 2) subActive = true
    this.setState({ initial, menu, subActive })
  }

  render() {

    let { offCanvas, menu, subActive, scrolled, initial } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = `header`
    if ( scrolled ) headerClass += ` header--scrolled`

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <div className='header__col'>
              <Link to='/' title='Madigan Capital' className='header__logo' {...props}>
                <Logo color={`#FFF`} />
              </Link>
            </div>
            <div className='header__col'>
              <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                <span className='lines'></span>
              </button>
              <button onClick={() => window && window.history.go(-1)} className='contact__hamburger active'>
                <span className='lines'></span>
              </button>
            </div>
            <div className='header__col'>
              <ul className='header__social'>
                <li><Link to='https://www.linkedin.com/company/madigan-capital/'><LinkedIn /></Link></li>
                <li><Link to='mailto:info@madigancapital.com'><Email /></Link></li>
              </ul>
            </div>
          </div>
        </header>
        <div className={`off-canvas${ offCanvas ? ' active' : '' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li>
                  <button type='button' className={`${menu[0] ? 'menu-active' : ''} ${initial ? 'menu-active' : ''}`} to='/about-us/' onMouseOver={() => this.menuRollover(0)}>About Us</button>
                  { menu[0] &&
                    <ul>
                      <li><Link to='/about-us/' {...props}>About Us</Link></li>
                      <li><Link to='/people/' {...props}>People</Link></li>
                      <li><Link to='/careers/' {...props}>Careers</Link></li>
                      <li><Link to='/esg/' {...props}>ESG</Link></li>
                    </ul>
                  }
                </li>
                <li>
                  <button type='button' className={`${menu[1] ? 'menu-active' : ''} ${initial ? 'menu-active' : ''}`} to='/our-work/' onMouseOver={() => this.menuRollover(1)}>Our Work</button>
                  { menu[1] &&
                    <ul>
                      <li><Link to='/our-work/' {...props}>Our Work</Link></li>
                      <li><Link to='/investors/' {...props}>Investors</Link></li>
                      <li><Link to='/borrowers/' {...props}>Borrowers</Link></li>
                    </ul>
                  }
                </li>
                <li><Link className={`${menu[2] ? 'menu-active' : ''} ${initial ? 'menu-active' : ''}`} to='/news/' onMouseOver={() => this.menuRollover(2)} {...props}>News</Link></li>
                <li><Link className={`${menu[3] ? 'menu-active' : ''} ${initial ? 'menu-active' : ''}`} to='/contact-us/' onMouseOver={() => this.menuRollover(3)} {...props}>Contact</Link></li>
              </ul>
            </nav>
          </div>
        </div>
        <Link to='/contact-us/' className='header__contact'>Contact Us</Link>
      </>
    )
  }
}

export default Header
