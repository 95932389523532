import React, {Component} from 'react'
import Link from '../utils/link'
import {Logo, LinkedIn, Email, ShortArrow} from './icons'
import Newsletter from './newsletter'

class Footer extends Component {
  state = {
    offCanvas: false,
    subActive: false,
    menu: [],
  }

  menuRollover = (item) => {
    this.setState({
      workActive: false,
    })
  }

  menuRollover = (key) => {
    let { subActive, menu } = this.state
    subActive = false
    if ( menu[key] ) {
      menu[key] = true
    } else {
      for ( let i = 0; i < menu.length; i++ ) {
        menu[i] = false
      }
      menu[key] = true
    }
    if (key < 2) subActive = true
    this.setState({ menu, subActive })
  }

  render() {
    let { offCanvas, menu, subActive } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__top'>
            <div className='footer__left'>
              <Link to='/' title='Madigan Capital' className='footer__logo'>
                <Logo color={`#E53B16`}/>
              </Link>

              <div className='footer__menu'>
                <ul>
                  <li><button type='button' className={menu[0] ? 'menu-active' : ''} to='/about-us/' onMouseOver={() => this.menuRollover(0)}>About Us</button></li>
                  <li><button type='button' className={menu[1] ? 'menu-active' : ''} to='/our-work/' onMouseOver={() => this.menuRollover(1)}>Our Work</button></li>
                  <li><Link className={menu[2] ? 'menu-active' : ''} to='/news/' onMouseOver={() => this.menuRollover(2)} {...props}>News</Link></li>
                  <li><Link className={menu[3] ? 'menu-active' : ''} to='/contact-us/' onMouseOver={() => this.menuRollover(3)} {...props}>Contact</Link></li>
                </ul>
                { menu[0] &&
                <ul className='sub-menu'>
                  <li><Link to='/about-us/' {...props}>About Us</Link></li>
                  <li><Link to='/people/' {...props}>People</Link></li>
                  <li><Link to='/careers/' {...props}>Careers</Link></li>
                </ul>
                }
                { menu[1] &&
                <ul className='sub-menu'>
                  <li><Link to='/our-work/' {...props}>Our Work</Link></li>
                  <li><Link to='/investors/' {...props}>Investors</Link></li>
                  <li><Link to='/borrowers/' {...props}>Borrowers</Link></li>
                </ul>
                }
              </div>
            </div>

            <div className='footer__right'>

              <div className='footer__links'>
                <div className='footer__address'>
                  <p>Madigan Capital Pty Ltd</p>
                  <p>Level 28, Governor Phillip Tower</p>
                  <p>1 Farrer Place, Sydney NSW 2000</p>
                </div>

                <div className='footer__socials'>
                  <Link to='https://www.linkedin.com/company/madigan-capital/'>
                    <LinkedIn color='#E53B16'/>
                  </Link>

                  <Link to='mailto:info@madigancapital.com'>
                    <Email color='#E53B16'/>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className='footer__bottom'>
            <div className='footer__terms'>
              <p>® Madigan Capital, {new Date().getFullYear()}. All Rights Reserved.</p>

              <div>
                <Link to='/esg/'>ESG</Link>
                <Link to='/privacy-policy/'>Privacy Policy</Link>
                <Link to='/terms-of-use/'>Terms of Use</Link>
              </div>
            </div>

            <div className='footer__disclaim'>
              <Link to='/disclaimer/'>Disclaimer</Link>
              <Link to='https://blkbox.com.au'>Site by BLKBOX</Link>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer
